var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":true}},[(_vm.success_loading)?_c('v-card',[_c('v-card-text',{staticClass:"d-flex justify-content-between"},[_c('div',[(_vm.details.email)?_c('h4',[_vm._v(_vm._s(_vm.details.email.address))]):_vm._e(),_c('h4',[_vm._v(_vm._s(_vm.details.first_name)+" "+_vm._s(_vm.details.last_name))])]),_c('div',[_c('h6',[_vm._v(" "+_vm._s(_vm.details.phone.phone_type)+" "+_vm._s(_vm.details.phone.phone_geo ? `, ${_vm.details.phone.phone_geo}` : "")+" ")]),_c('h6',[_vm._v(_vm._s(_vm.details.phone.carrier))]),(_vm.details.phone && _vm.details.phone.number)?_c('h6',[_vm._v(" "+_vm._s(_vm.details.phone.number)+" "),(_vm.details.phone.is_valid)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check ")]):_c('v-icon',{attrs:{"color":_vm.details.phone.is_valid === false ? 'red' : 'orange',"small":""}},[_vm._v("mdi-close ")]),(
              _vm.details.phone.has_watsapp === true ||
              _vm.details.phone.has_watsapp === false
            )?_c('v-icon',{attrs:{"color":_vm.details.phone.has_watsapp === false ? 'gray' : '#008000',"small":""}},[_vm._v("mdi-whatsapp")]):_vm._e()],1):_vm._e()]),_c('div',[(_vm.details.revenue)?_c('h6',[_vm._v("Revenue: $"+_vm._s(_vm.details.revenue))]):_vm._e(),(_vm.details.total_leads)?_c('h6',[_vm._v(" Total leads: "+_vm._s(_vm.details.total_leads)+" ")]):_vm._e()]),_c('div',[_c('div',[_vm._v("Created at "+_vm._s(_vm.renderDate(_vm.details.created_at)))]),_c('div',[_vm._v("Updated at "+_vm._s(_vm.renderDate(_vm.details.updated_at)))])])]),(_vm.details.sources)?_c('v-card-text',[_c('h6',[_vm._v("Sources")]),_c('v-data-table',{attrs:{"headers":_vm.sourcesHeaders,"items":_vm.details.sources,"hide-default-footer":"","itemsPerPage":+_vm.details.sources.length,"disable-sort":""},scopedSlots:_vm._u([{key:"header.name",fn:function({ header }){return [_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},_vm._l((_vm.sourcesHeaders.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.referer",fn:function({ item }){return [_c('a',{attrs:{"href":item.referer,"target":"_blank"}},[_vm._v(_vm._s(item.referer))])]}}],null,true)})],1):_vm._e(),(_vm.details.addresses)?_c('v-divider',{staticClass:"mx-4"}):_vm._e(),(_vm.details.addresses)?_c('v-card-text',[_c('h6',[_vm._v("Addresses")]),_c('v-data-table',{attrs:{"headers":_vm.addressesHeaders,"items":_vm.details.addresses,"hide-default-footer":"","itemsPerPage":+_vm.details.addresses.length,"disable-sort":""},scopedSlots:_vm._u([{key:"header.name",fn:function({ header }){return [_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},_vm._l((_vm.addressesHeaders.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true)})],1):_vm._e(),(_vm.details.broadcasts)?_c('v-divider',{staticClass:"mx-4"}):_vm._e(),(_vm.details.broadcasts)?_c('v-card-text',[_c('h6',[_vm._v("Broadcasts")]),_c('v-data-table',{attrs:{"headers":_vm.broadcastsHeaders,"items":_vm.details.broadcasts,"hide-default-footer":"","itemsPerPage":+_vm.details.broadcasts.length,"disable-sort":""},scopedSlots:_vm._u([{key:"header.name",fn:function({ header }){return [_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},_vm._l((_vm.broadcastsHeaders.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ header, value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true)})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }